<template>
  <SettingsLayout>
    <div class="row">
      <div class="col-md-12">
        <div class="exchange_setting_heading">
          <h3 class="fw-bold">2F Authentication Setting</h3>
        </div>
      </div>
    </div>
    <!-- Heading  -->

    <div class="row mt-2">
      <div class="col-md-12">
        <ul class="list-group">
          <li class="list-group-item py-3">
            <div
              class="
                form-check
                d-flex
                justify-content-between
                align-items-center
                ps-0
              "
            >
              <label
                class="form-check-label label_fw w-100 form-check_cus"
                for="flexRadioDefault1"
              >
                Email Verification
                <br /><span class="badge bg-success fw-400">
                  Most Recommended</span
                >
              </label>

              <input
                v-model="selected"
                value="2"
                class="form-check-input form-check_cus"
                type="radio"
                name="flexRadioDefault"
                id="flexRadioDefault1"
                v-on:change="btn_show = true"
              />
            </div>
          </li>
          <!-- <li class="list-group-item py-3">
            <div
              class="
                form-check
                d-flex
                justify-content-between
                align-items-center
                ps-0
              "
            >
              <label
                class="form-check-label label_fw w-100 form-check_cus"
                for="flexRadioDefault2"
              >
                Mobile/SMS Verification
                <br /><span class="badge bg-success fw-400">Recommended</span>
              </label>

              <input
                v-model="selected"
                value="1"
                class="form-check-input form-check_cus"
                type="radio"
                name="flexRadioDefault"
                id="flexRadioDefault2"
                v-on:change="btn_show = true"
              />
            </div>
          </li> -->
          <li class="list-group-item py-3">
            <div
              class="
                form-check
                d-flex
                justify-content-between
                align-items-center
                ps-0
              "
            >
              <label
                class="form-check-label label_fw w-100 form-check_cus"
                for="flexRadioDefault3"
              >
                None<br />
                <span class="badge bg-danger fw-400">Not Recommended</span>
              </label>

              <input
                v-model="selected"
                value="0"
                class="form-check-input form-check_cus"
                type="radio"
                name="flexRadioDefault"
                id="flexRadioDefault3"
                v-on:change="btn_show = true"
              />
            </div>
          </li>
        </ul>
        <br />

        <div class="col-md-6 mt-5 mb-4 offset-md-4">
          <button
            v-if="btn_show"
            v-on:click="change_setting"
            class="btn btn-dark col-8 col-md-8 shadow-none"
          >
            Update
          </button>
          <div
            class="btn btn-dark mx-auto"
            style="min-width: 340px; text-align: center; margin: 0 auto"
            v-if="loading"
          >
            <div class="spinner-border" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Content  -->
  </SettingsLayout>
</template>



<script>
import SettingsLayout from "@/layouts/SettingsLayout.vue";
import ApiClass from "@/api/api";
export default {
  name: "TwoFactor",
  data() {
    return {
      selected: [],
      btn_show: false,
      loading: false,
    };
  },
  components: {
    SettingsLayout,
  },
  mounted() {
    this.get_setting();
  },
  methods: {
    async get_setting() {
      await ApiClass.getRequest("2fa/get", true).then((res) => {
        if (res.data.status_code == 1) {
          this.selected = res.data.data._2fa;
        }
        console.log(res);
      });
    },
    async change_setting() {
      this.btn_show = false;
      this.loading = true;
      await ApiClass.postRequest("2fa/update", true, {
        two_factor: this.selected,
      }).then((res) => {
        if (res.data.status_code == 1) {
          this.$swal({
            toast: true,
            position: "top-end",
            icon: "success",
            title: res.data.message,
            showConfirmButton: false,
            timer: 3000,
          });
          this.get_setting();
        }
        this.loading = false;
      });
    },
  },
};
</script>


<style scoped>
.label_fw {
  font-weight: 500;
}
.fw-400 {
  font-weight: 400;
}
label.form-check_cus,
input.form-check_cus {
  cursor: pointer;
}
</style>