<template>
  <SettingsLayout>
    <div
      class="row"
      v-if="user.user_kyc_status == 'new' || user.user_kyc_status == 'rejected'"
    >
      <div class="col-md-12">
        <div class="exchange_setting_heading">
          <h3 class="fw-500">KYC Detail</h3>
        </div>
        <!-- show rejected messages -->
        <div
          v-show="user.user_kyc_status == 'rejected'"
          style="text-align: center"
        >
          <span
            >Your Kyc is <b>Rejected</b> due to following Reasons. <br />
            <p style="font-size: small; color: red">
              {{ user.user_kyc_status_message }}
            </p></span
          >
        </div>
      </div>

      <!-- Heading  -->
      <div class="col-md-12">
        <form
          @submit.prevent="handleSubmit"
          class="row g-3 justify-content-center"
        >
          <h5 class="fw-500 m-0 mt-4">Basic Detail:</h5>
          <!-- heading  -->
          <div class="col-md-6">
            <label for="first_name" class="form-label label_fw"
              >First Name</label
            >

            <input
              autocomplete="off"
              type="text"
              v-model="formdata.first_name"
              class="form-control input_style_cus shadow-none"
              :class="{
                'is-invalid': isSubmitted && $v.formdata.first_name.$error,
              }"
              id="first_name"
              v-on:keypress="isLetter($event)"
              placeholder="Enter First Name"
            />

            <div
              v-if="isSubmitted && !$v.formdata.first_name.required"
              class="invalid-feedback"
            >
              First name is required
            </div>
          </div>
          <!-- First name  -->
          <div class="col-md-6">
            <label for="middle_name" class="form-label label_fw"
              >Middle Name</label
            >
            <input
              type="text"
              autocomplete="off"
              v-model="formdata.middle_name"
              class="form-control input_style_cus shadow-none"
              :class="{
                'is-invalid': isSubmitted && $v.formdata.middle_name.$error,
              }"
              id="middle_name"
              v-on:keypress="isLetter($event)"
              placeholder="Enter Middle Name"
            />
          </div>
          <!-- Middle name  -->
          <div class="col-md-6">
            <label for="last_name" class="form-label label_fw">Last Name</label>
            <input
              type="text"
              autocomplete="off"
              v-model="formdata.last_name"
              class="form-control input_style_cus shadow-none"
              :class="{
                'is-invalid': isSubmitted && $v.formdata.last_name.$error,
              }"
              id="last_name"
              v-on:keypress="isLetter($event)"
              placeholder="Enter Last Name"
            />

            <div
              v-if="isSubmitted && !$v.formdata.last_name.required"
              class="invalid-feedback"
            >
              Last name is required
            </div>
          </div>
          <!-- last name  -->
          <div class="col-md-6">
            <label for="dob" class="form-label label_fw">Date of Birth</label>

            <date-picker
              v-model="formdata.dob"
              valueType="format"
              class="form-control input_style_cus shadow-none"
              :class="{ 'is-invalid': isSubmitted && $v.formdata.dob.$error }"
              id="dob"
            ></date-picker>

            <div
              v-if="isSubmitted && !$v.formdata.dob.required"
              class="invalid-feedback"
            >
              Date of birth is required
            </div>
          </div>
          <!-- date of birth  -->
          <div class="col-12">
            <label for="address" class="form-label label_fw">Address</label>
            <textarea
              v-model="formdata.address"
              class="form-control input_style_cus shadow-none"
              :class="{
                'is-invalid': isSubmitted && $v.formdata.address.$error,
              }"
              placeholder="Enter Address"
              rows="4"
              cols="50"
            ></textarea>
            <div
              v-if="isSubmitted && !$v.formdata.address.required"
              class="invalid-feedback"
            >
              Address is required
            </div>
          </div>
          <!-- Address  -->
          <hr class="mt-5 hr_style" />
          <!----------------------------Basic Detail---------------------------------->
          <h5 class="fw-500 m-0 mt-4">Identity Verification:</h5>
          <!-- heading  -->
          <div class="col-md-6">
            <label for="document_type" class="form-label label_fw"
              >Document Type</label
            >
            <select
              v-model="formdata.document_type"
              id="document_type"
              class="form-select input_style_cus shadow-none"
              :class="{
                'is-invalid': isSubmitted && $v.formdata.document_type.$error,
              }"
            >
              <option selected>Choose...</option>
              <option value="aadhaar">Aadhaar Card</option>
              <option value="driving">Driving License</option>
              <option value="voter">Voter Card</option>
              <option value="passport">Passport</option>
            </select>
            <div
              v-if="isSubmitted && !$v.formdata.document_type.required"
              class="invalid-feedback"
            >
              Document type is required
            </div>
          </div>
          <!-- Document Type  -->
          <div class="col-md-6">
            <label for="document_number" class="form-label label_fw"
              >Document Number</label
            >
            <input
              autocomplete="off"
              v-model="formdata.document_number"
              type="text"
              class="form-control input_style_cus shadow-none"
              :class="{
                'is-invalid': isSubmitted && $v.formdata.document_type.$error,
              }"
              id="document_number"
              v-on:keypress="count($event, formdata.document_number.length)"
              placeholder="Enter Document Number"
            />

            <div
              v-if="isSubmitted && !$v.formdata.document_number.required"
              class="invalid-feedback"
            >
              Document number is required
            </div>
          </div>
          <!-- Document Number  -->
          <div class="col-md-6 col-6">
            <label for="inputDoc1" class="form-label label_fw"
              >Upload Front</label
            >
            <img
              :src="pan_card1"
              id="img_url1"
              @click="check('document_type_pan1', 'img_url1')"
              class="filter_img img-thumbnail"
              alt="img"
            />

            <input
              type="file"
              id="document_type_pan1"
              v-on:change="first($event, 'first_image')"
              accept="image/*"
              hidden
            />
            <div v-if="isSubmitted && this.first_image == null" class="error">
              Image is required
            </div>
          </div>
          <!-- Front Image-->
          <div class="col-md-6 col-6">
            <label for="inputName2" class="form-label label_fw"
              >Upload Back</label
            >
            <img
              :src="pan_card2"
              id="img_url2"
              @click="check('document_type_pan2', 'img_url2')"
              class="filter_img img-thumbnail"
              alt="img"
            />

            <input
              type="file"
              v-on:change="first($event, 'second_image')"
              id="document_type_pan2"
              accept="image/*"
              hidden
            />
            <div v-if="isSubmitted && this.second_image == null" class="error">
              Image is required
            </div>
          </div>
          <!-- Back Image-->
          <hr class="mt-5 hr_style" />
          <!----------------------------Identity Verification---------------------------------->
          <h5 class="fw-500 m-0 mt-4">PAN Card Verification:</h5>
          <!-- heading  -->
          <div class="col-md-6">
            <label for="document_type_p" class="form-label label_fw"
              >Document Type</label
            >
            <select
              v-model="formdata.document_type_p"
              id="document_type_p"
              class="form-select input_style_cus shadow-none shadow-none"
              :class="{
                'is-invalid': isSubmitted && $v.formdata.document_type_p.$error,
              }"
            >
              <option selected>PAN Card</option>
            </select>
            <div
              v-if="isSubmitted && !$v.formdata.document_type_p.required"
              class="invalid-feedback"
            >
              Document is required
            </div>
          </div>
          <!-- Document Type  -->
          <div class="col-md-6">
            <label for="document_number2" class="form-label label_fw"
              >Document Number</label
            >
            <input
              autocomplete="off"
              v-model="formdata.document_number2"
              type="text"
              class="form-control input_style_cus shadow-none"
              :class="{
                'is-invalid':
                  isSubmitted && $v.formdata.document_number2.$error,
              }"
              id="document_number2"
              placeholder="Enter Document Number"
              v-on:keypress="count($event, formdata.document_number2.length)"
            />

            <div
              v-if="isSubmitted && !$v.formdata.document_number2.required"
              class="invalid-feedback"
            >
              Document number is required
            </div>
          </div>
          <!-- Document Number  -->
          <div class="col-md-12">
            <label for="inputDoc1" class="form-label label_fw"
              >Upload Document</label
            >

            <img
              :src="pan_card"
              id="img_url"
              @click="check('document_type_pan', 'img_url')"
              class="filter_img img-thumbnail"
              alt="img"
            />

            <input
              type="file"
              id="document_type_pan"
              v-on:change="first($event, 'third_image')"
              accept="image/*"
              hidden
            />
            <div v-if="isSubmitted && this.third_image == null" class="error">
              Image is required
            </div>
          </div>
          <!-- Front Image-->
          <!----------------------------PAN Card Verification---------------------------------->
          <div class="col-md-6 mt-5 mb-4 text-center">
            <button
              type="submit"
              class="btn btn-dark col-8 col-md-8 shadow-none"
            >
              Save
            </button>
          </div>
          <!--button-->
        </form>
      </div>
    </div>

    <div class="row" v-else>
      <div class="col-md-12">
        <div
          class="pending_kyc d-flex justify-content-center align-items-center"
        >
          <span v-show="user.user_kyc_status == 'completed'">
            <span v-html="tick" class="p-0"></span>Your Kyc is Verified and
            Completed.
          </span>
          <span v-show="user.user_kyc_status == 'pending'"
            >Your Kyc has been submitted successfully and Pending For
            Approval.</span
          >
        </div>
      </div>
    </div>
    <!-- Content  -->
  </SettingsLayout>
</template>


<script>
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import ApiClass from "@/api/api";
import { required } from "vuelidate/lib/validators";
import SettingsLayout from "@/layouts/SettingsLayout.vue";
import img from "@/assets/img-preview.png";
import img1 from "@/assets/img-preview.png";
import img2 from "@/assets/img-preview.png";
export default {
  name: "KycSetting",
  components: {
    SettingsLayout,
    // DatePick,
    DatePicker,
  },
  data() {
    return {
      tick: '<svg xmlns="http://www.w3.org/2000/svg" class="align-middle" width="40" height="40" viewBox="0 0 24 24" style="fill: var(--green);transform: ;msFilter:;"><path d="M12 2C6.486 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.514 2 12 2zm-1.999 14.413-3.713-3.705L7.7 11.292l2.299 2.295 5.294-5.294 1.414 1.414-6.706 6.706z"></path></svg>',
      first_image: null,
      second_image: null,
      third_image: null,
      formdata: {
        first_name: null,
        middle_name: null,
        last_name: null,
        dob: null,
        address: null,
        document_type: null,
        document_number: null,
        document_type_p: null,
        document_number2: null,
      },
      pan_card: img,
      pan_card1: img1,
      pan_card2: img2,
      isSubmitted: false,
      date: new Date(),
      user: {},
    };
  },
  validations: {
    formdata: {
      first_name: {
        required,
      },
      middle_name: {},
      last_name: {
        required,
      },
      address: {
        required,
      },
      dob: {
        required,
      },
      document_type: {
        required,
      },
      document_number: {
        required,
      },
      document_type_p: {
        required,
      },
      document_number2: {
        required,
      },
    },
  },
  mounted() {
    this.userDatas();
  },
  methods: {
    userDatas() {
      var userdata = localStorage.getItem("user");
      this.user = JSON.parse(userdata);
    },
    check(id, img) {
      document.getElementById(id).click();
      var x = document.getElementById(id);
      x.onchange = () => {
        var a = document.getElementById(id);
        const [file] = a.files;
        if (file) {
          console.log(id);
          var b = document.getElementById(img);
          b.src = URL.createObjectURL(file);
        }
      };
    },

    async handleSubmit() {
      this.isSubmitted = true;

      this.$v.$touch();
      if (
        !this.$v.$invalid &&
        this.first_image != null &&
        this.second_image != null &&
        this.third_image != null
      ) {
        var all_data = new FormData();
        all_data.append("identity_front_path", this.first_image);
        all_data.append("identity_back_path", this.second_image);
        all_data.append("pan_card_path", this.third_image);
        all_data.append("first_name", this.formdata.first_name);
        all_data.append("middle_name", this.formdata.middle_name);
        all_data.append("last_name", this.formdata.last_name);
        all_data.append("date_birth", this.formdata.dob);
        all_data.append("address", this.formdata.address);
        all_data.append("identity_type", this.formdata.document_type);
        all_data.append("identity_number", this.formdata.document_number);
        all_data.append("pan_card_number", this.formdata.document_number2);
        var headers = {
          "Content-Type": "multipart/form-data",
        };
        await ApiClass.postRequest(
          "userkyc/create",
          true,
          all_data,
          headers
        ).then((res) => {
          // console.log(res);
          // console.log(res.data.status_code);
          if (res.data.status_code == 1) {
            this.$swal({
              toast: true,
              position: "top-end",
              icon: "success",
              title: res.data.message,
              showConfirmButton: false,
              timer: 3000,
            });
            this.user.user_kyc_status = "pending";
            localStorage.setItem("user", JSON.stringify(this.user));
            this.userDatas();
          } else {
            this.$swal({
              toast: true,
              position: "top-end",
              icon: "error",
              title: res.data.message,
              showConfirmButton: false,
              timer: 3000,
            });
          }
        });
      }
    },
    first(event, var_name) {
      if (var_name == "first_image") {
        this.first_image = event.target.files[0];
        console.log(this.first_image);
      } else if (var_name == "second_image") {
        this.second_image = event.target.files[0];
        console.log(this.second_image);
      } else if (var_name == "third_image") {
        this.third_image = event.target.files[0];
        console.log(this.third_image);
      }
    },
    checkColor(status) {
      if (status == "pending") {
        return {
          backgroundColor: "#fffd29",
          color: "#000",
        };
      }
      return {
        backgroundColor: "#008000",
        color: "#fff",
      };
    },
    isLetter(e) {
      let char = String.fromCharCode(e.keyCode); // Get the character
      if (/^[A-Za-z]+$/.test(char)) return true;
      // Match with regex
      else e.preventDefault(); // If not match, don't add to input text
    },
    count(e, number) {
      if (number > 19) {
        e.preventDefault();
      }
    },
  },
};
</script>


<style scoped>
.label_fw {
  font-weight: 500;
}
.fw-500 {
  font-weight: 600;
}
textarea {
  resize: none;
}
.input_style_cus:focus {
  box-shadow: 0;
  border-color: rgb(170, 170, 170);
}
.filter_img {
  width: 120px;
  height: 120px;
  display: block;
  margin-top: 10px;
}
.hr_style {
  color: #cfcfcf !important;
  opacity: 1 !important;
}
.error {
  font-weight: 500;
  font-size: 11px;
  color: #dc3545;
}
.mx-datepicker {
  border-style: none;
  padding-top: 0px;
  padding-left: 0px;
  width: 480px;
}
.invalid-feedback {
  font-weight: 500;
  font-size: 11px;
}
.mx-datepicker {
  width: 100%;
  padding: 0;
}

.pending_kyc {
  min-height: 80px;
}
.pending_kyc span {
  display: inline-block;
  padding: 25px 30px;
  color: var(--text);

  font-size: 18px;
  font-weight: 500;
}
</style>