<template>
  <SettingsLayout>
      <div class="row">
        <div class="col-md-12">
          <div class="exchange_setting_heading">
            <h3 class="fw-bold">Profile Detail</h3>
          </div>
          <!--heading-->
        </div>
      </div>
      <div class="row mt-2 list-group-item ps-0 m-0">
        <div v-if='this.userData' class="col-md-12 ">
          <div class="name_setting_box mb-3">
            <p class="m-0">Name:</p>
            <p class="m-0">{{this.userData.name}}</p>
          </div>
          <!--Name-->
          <div class="name_setting_box mb-3">
            <p class="m-0">Mobile No. :</p>
            <p class="m-0">{{this.userData.mobile}}</p>
          </div>
            <!--Mobile-->
          <div class="name_setting_box mb-3">
            <p class="m-0">Email Id:</p>
            <p class="m-0">{{this.userData.email}}</p>
          </div>
          <!--Email-->
          <div class="name_setting_box mb-3">
            <p class="m-0">Status:</p>
            <p class="m-0">{{this.status}}</p>
          </div>
           <!--Status-->
        </div>
      </div>
      <!-- Content  -->
  </SettingsLayout>
</template>



<script>
import SettingsLayout from "@/layouts/SettingsLayout.vue";
import ApiClass from "@/api/api";
// import ActivityLogVue from './ActivityLog.vue';
// import LoginVue from '../Auth/Login.vue';
export default {
  name: "Profile",
  components: {
    SettingsLayout,
  },
  data(){
    return{
      userData:null,
      status:null
    }
  },
  mounted(){
    this.getUserInfo();
  },
  methods:{
  async getUserInfo(){
   await ApiClass.getNodeRequest('user/get',true)
    .then((res)=>{
      if(res.data.status_code==1){
        this.userData=res.data.data;
        this.status=res.data.data.status==true?'Active':'Blocked';
        console.log(this.userData.name)
       
      }
      
    })
    
  }
}
};

</script>


<style scoped>
.name_setting_box p:nth-child(1) {
  font-weight: 600;
}
.card_cus  p{
  color: var(--text);
}
</style>